export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const SET_ACCESS_LEVELS='SET_ACCESS_LEVELS';
export const SET_DASHBOARD_ACCESS_LEVELS='SET_DASHBOARD_ACCESS_LEVELS';
export const GET_CALLS_COMPLETED = 'GET_CALLS_COMPLETED';
export const GET_SALVAGE_VEHICLE_COUNT = 'GET_SALVAGE_VEHICLE_COUNT';
export const GET_DEPOSITS = 'GET_DEPOSITS';
export const GET_STORAGE_COUNT = 'GET_STORAGE_COUNT';
export const GET_RELEASE_UNITS = 'GET_RELEASE_UNITS';
export const GET_NO_AVRREPORT = 'GET_NO_AVRREPORT';
export const GET_PAYLESS_INVENTORY = 'GET_PAYLESS_INVENTORY';
export const GET_IMPOUND_CALLS = 'GET_IMPOUND_CALLS';
export const GET_TITLED_CALLS = 'GET_TITLED_CALLS';

export const ANY_OF_YOUR_ACTION_TYPES = 'ANY_OF_YOUR_ACTION_TYPES';
export const ANY_OF_YOUR_ACTION_TYPES_FINISH = 'ANY_OF_YOUR_ACTION_TYPES_FINISH';

export const DISP_GET_CALL_LIST='DISP_GET_CALL_LIST';
export const DISP_GET_TRUCK_DETAILS='DISP_GET_TRUCK_DETAILS';
export const DISP_GET_TOTAL_CREATED_CALLS_FOR_TODAY='DISP_GET_TOTAL_CREATED_CALLS_FOR_TODAY';
export const SET_NOTIFY_REF = 'SET_NOTIFY_REF';

export const ADMIN_GET_INV_MORETHAN='ADMIN_GET_INV_MORETHAN';
export const ADMIN_GET_INV_MORETHAN_DAYS='ADMIN_GET_INV_MORETHAN_DAYS';
export const ADMIN_GET_EXPIRE_ACCOUNTS='ADMIN_GET_EXPIRE_ACCOUNTS';
export const ADMIN_GET_EXPIRE_ACCOUNTS_DAYS='ADMIN_GET_EXPIRE_ACCOUNTS_DAYS';


export const ADMIN_GET_ALL_ACCOUNTS='ADMIN_GET_ALL_ACCOUNTS';
//export const ADMIN_GET_EXPIRED_ACCOUNTS='ADMIN_GET_EXPIRED_ACCOUNTS';
//export const ADMIN_GET_ACCOUNTBY_DEPARTMENT='ADMIN_GET_ACCOUNTBY_DEPARTMENT';
//export const ADMIN_GET_CASHONLY_ACCOUNTS='ADMIN_GET_CASHONLY_ACCOUNTS';