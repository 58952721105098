import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {Doughnut} from 'react-chartjs-2';
let _ = require('underscore');
let graphColors;
let graphOutlines;
let hoverColor;
let filterData=[];
class StorageCountChart extends Component{
    constructor(props){
        super(props);
        this.state={
            chartObj1:{
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [],
                    hoverBackgroundColor: [],
                    borderColor: []
                }]
            },   
        }   
    }
    componentWillMount(){
        this.storageCount(); 
    }
    storageCount=()=>{
        graphColors = [];
        graphOutlines = [];
        hoverColor = [];
        
       
        let internalDataLength = this.props.reportDashboard.storageCount.storageResponseDetails.length;
        let col = 0;
        while (col <= internalDataLength) {
            let randomR = Math.floor((Math.random() * 130) + 100);
            let randomG = Math.floor((Math.random() * 130) + 100);
            let randomB = Math.floor((Math.random() * 130) + 100);
        
            let graphBackground = "rgb(" 
                    + randomR + ", " 
                    + randomG + ", " 
                    + randomB + ")";
            graphColors.push(graphBackground);
            
            let graphOutline = "rgb(" 
                    + (randomR - 80) + ", " 
                    + (randomG - 80) + ", " 
                    + (randomB - 80) + ")";
            graphOutlines.push(graphOutline);
            
            let hoverColors = "rgb(" 
                    + (randomR + 25) + ", " 
                    + (randomG + 25) + ", " 
                    + (randomB + 25) + ")";
            hoverColor.push(hoverColors);
            
        col++;
        };

        
        let _chartObj1=this.state.chartObj1;            
        _chartObj1.labels=[];
        _chartObj1.datasets[0].data=[];
        _chartObj1.datasets[0].backgroundColor=graphColors;
        _chartObj1.datasets[0].hoverBackgroundColor=hoverColor; 
        _chartObj1.datasets[0].borderColor=graphOutlines; 
        filterData=_.groupBy(this.props.reportDashboard.storageCount.storageResponseDetails,'lotLocation');
        //console.log("arrStorageDaysss ::",filterData);
        for(let i=0;i<Object.keys(filterData).length;i++){
            let items=filterData[Object.keys(filterData)[i]];
            _chartObj1.labels.push(items[0]['lotLocation']);
            _chartObj1.datasets[0].data.push(items.length);                                
        }
    }
    handleElementClick(evt){
        if(evt.length>0){
            this.props.history.push('/reportschart/storagedrilldowncountchart?text='+evt[0]._chart.active[0]._view.label+'&index='+evt[0]._chart.active[0]._index);
        }
    }
    render(){
        const options1={
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: 'top'
            },
            title: {
                display: true,
                text: 'Storage Count'
            }    
        }
        return(
            <div class="row"> 
            <div class="pull-right col-md-12"> 
                    <Link class="btn btn-info" to="/reports-dashboard"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back </Link>
                </div> 
                <div class="clear"></div>
                
                    <div class="col-md-8 col-md-offset-2">
                        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.14)' }}>
                            <Doughnut height="440" data={this.state.chartObj1} onElementsClick={(evt) => this.handleElementClick(evt)} options={options1} />
                        </div>
                    </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        reportDashboard:state.reportDashboard
        //refNotify:state.refNotify
    }
};

export default connect(mapStateToProps)(StorageCountChart);