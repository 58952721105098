import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authenticateActions from '../../actions/authenticateActions';
import {notify} from '../../util/notifications';
import { FormValidation } from "calidation";
import Background from '../../assets/img/bg_body.jpg';
import {deployment_env} from '../../util/constants'
import './login.css';
const pageTitle="Login";
class Login extends Component {
    constructor() {
        super();
        this.state = {
            userName: '',
            password: '',
            subDomain:''
        }
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSubDomain=this.getSubDomain.bind(this)
    }
    componentWillMount=()=>{
        this.getSubDomain();
    }
    getSubDomain=()=>{
        let host = window.location.host;
        host=host.replace("www.",'');
        host=host.replace('.com','');
        if (host.split('.').length>1) {
            this.setState({subDomain:(host.split('.'))[0]});
        }else{
            if(deployment_env==='production'){
                this.setState({subDomain:'www'});
            }
        }
    }
    onChangeValues = (event) => {
        let _state = this.state;
        _state[event.currentTarget.name] = event.currentTarget.value;
        this.setState(_state
        );
    }
    handleSubmit = (fields, errors, isValid) => {
        if (fields.isValid) {
            const { dispatch, history } = this.props;
            dispatch(authenticateActions.authenticateUser(fields.fields.userName, fields.fields.password,this.state.subDomain,(successResp) => {
               if(successResp.loginUserResp.userDetails.isReset===0){
                   let userid=successResp.loginUserResp.userDetails.userId;
                   let resetPwdToken=successResp.pwdResetToken;
                   history.push('/resetPassword?forgetToken='+resetPwdToken+'&userid='+userid)
               }else{                   
                    history.push('/');
               }
            }, (error) => {
                notify.createNotification('error','Invalid UserName/Password',this.props.refNotify);
            }));
        } else {
            //notify.createNotification('error','Something is wrong',this.props.refNotify);
            console.log("Please fill all the required fields", errors);
        }
    }
    render() {
        document.title=pageTitle;
        const config = {
            userName: {
                isRequired: "User name is required!"
            },
            password: {
                isRequired: "Password field required!"
            }
        };
        return (
            <section className="wrapper" style={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: "no-repeat,no-repeat",
                backgroundPosition: "center center,center center",
                backgroundSize: "cover,cover"
            }}>
                <div className="row row-table" style={{ height: '100%', padding: '50px 0px' }}>
                    <div className="col-lg-3 col-md-6 col-sm-8 col-xs-12 align-middle">
                        <div className="panel panel-default">
                            <p className="text-center mb-lg">
                                <br />
                                <a href="#">
                                    <img src={require('../../assets/img/logo-2-white.png')} style={{ height: '70px' }} alt="ACT" className="block-center img-rounded" />
                                </a>
                            </p>
                            <p className="text-center mb-lg">
                                <strong>SIGN IN TO CONTINUE.</strong>
                            </p>
                            <div className="panel-body">
                                <FormValidation name="frmLogin" className="mb-lg" config={config}
                                    onSubmit={this.handleSubmit
                                    } >
                                    {({ fields, errors, submitted }) => (
                                        <Fragment>
                                            <div className={(submitted && errors.userName) ? "form-group has-feedback has-error has-danger" : "form-group has-feedback"}>
                                                <input id="userName" name="userName" type="text" placeholder="Enter User name"
                                                    className="form-control" value={fields.userName} onChange={(e) => {
                                                        this.onChangeValues(e);
                                                    }} />
                                                <span className="fa fa-envelope form-control-feedback text-muted"></span>
                                                {(submitted && errors.userName) ?
                                                    <div className="invalid-feedback with-errors" >
                                                        <span className="text-danger">{errors.userName}</span>
                                                    </div>
                                                    : null}</div>
                                            <div className={(submitted && errors.password) ? "form-group has-feedback has-error has-danger" : "form-group has-feedback"}>
                                                <input id="password" name="password" type="password" placeholder="Password"
                                                    className="form-control" value={fields.password} onChange={(e) => {
                                                        this.onChangeValues(e);
                                                    }} />
                                                <span className="fa fa-lock form-control-feedback text-muted"></span>
                                                {(submitted && errors.password) ? <div className="invalid-feedback with-errors" >
                                                    <span className="text-danger">{errors.password}</span></div> : null}
                                            </div>
                                            <div className="clearfix">
                                                <div className="checkbox c-checkbox pull-left mt0">
                                                    <label>
                                                        <input type="checkbox" value="" />
                                                        <span className="fa fa-check"></span>Remember Me</label>
                                                </div>
                                                <div className="pull-right">
                                                    {/* <Link to="/forgetpassword" className="recoveryTag">Account Recovery</Link> */}
                                                    <Link to="/accountrecovery" className="recoveryTag">Account Recovery</Link>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-block btn-primary" >Login </button>
                                        </Fragment>
                                    )}
                                </FormValidation>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        refNotify:state.refNotify
    }
};

export default withRouter(connect(mapStateToProps)(Login))