import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer class="sticky-footer bg-white">              
                <div class="copyright text-center my-auto">
                   <span>Copyright © {new Date().getFullYear()} ACT Towing LLC, All Rights Reserved</span>
                </div>               
            </footer>
        );
    }
}

export default Footer;