import * as actionTypes from '../actions/actionTypes';
import moment from 'moment'
const defaultRef={};       

export const setNotificationRefReducer = (state = defaultRef, action) => {
    switch (action.type) {
        case actionTypes.SET_NOTIFY_REF:
            return action.refNotify ;
        default:
            return state;
    }
  };