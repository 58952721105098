import React, { Component } from 'react';
import {connect} from  'react-redux';
import { Link } from 'react-router-dom';
import * as dispatchActions from '../../actions/dispatchDashboardActions';
const dispatchDashboardTailsTitles={
  TrucksAvailable:'Trucks available',
  UnassignedCalls:'Unassigned calls',
  TripsCompleted:'Trips completed',
  InProgress:'In Progress'
}
class Dispatcher extends Component {
    constructor(props) {
        super(props);       
      }
      componentWillMount=()=> { 
        const {dispatch} =this.props;
        dispatch(dispatchActions.getCallList()).then(
          dispatch(dispatchActions.getTruckDetails()).then(
            dispatch(dispatchActions.getTotalClearedCallsForToday())
          )
        ); 
      }
        available=()=> {
            var count = 0;
            this.props.dispatchDashboard.truckDetails.map(item => {
              if (item.truckStatus === 'available' && item.serviceStatus !== 'Out of Service') {
                count += 1;
              } return null;
            })
            return count;
        }
        
          unassigned=()=> {
            var count = 0;
            this.props.dispatchDashboard.callList.callList.map(item => {
              if (item.callStatus === 'Unassigned') {
                count +=1;
              } return null;
            })
            return count;
          }
        
          inProgress=()=> {
            var count = 0;
            this.props.dispatchDashboard.callList.callList.map(item => {
              if (item.callStatus !== 'Unassigned'){
                count += 1;
              } return null;
            })
            return count;
          }
        
         
      
    render() {
        return ( 
           
             <>
             {
               this.props.tailsAccessList[dispatchDashboardTailsTitles.TrucksAvailable.toLowerCase()]?
               <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"> 
                      <div class="card card-stats">
                          <div class="card-header" data-background-color="green"><i style={{width:'42px'}} class="fa fa-2x fa-truck" aria-hidden="true"></i></div>
                          <div class="card-content">
                              <p class="category">Trucks available</p>
                              <span class="title" style={{fontSize: '25px'}}>{ this.available() }</span>
                          </div>                       
                      </div> 
                    </div>:null
             }
             
             {
               this.props.tailsAccessList[dispatchDashboardTailsTitles.UnassignedCalls.toLowerCase()]?
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"> 
                      <div class="card card-stats">
                          <div class="card-header" data-background-color="red"><i style={{width:'42px'}} class="fa fa-2x fa-phone" aria-hidden="true"></i></div>
                          <div class="card-content">
                              <p class="category">Unassigned invoices</p>
                              <span class="title" style={{fontSize: '25px'}}>{ this.unassigned() }</span>
                          </div>                       
                      </div> 
                    </div>:null
             }
             {
               this.props.tailsAccessList[dispatchDashboardTailsTitles.TripsCompleted.toLowerCase()]?
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"> 
                      <div class="card card-stats">
                          <div class="card-header" data-background-color="purple"><i style={{width:'42px'}} class="fa fa-2x fa-th-list" aria-hidden="true"></i></div>
                          <div class="card-content">
                              <p class="category">Invoices completed</p>
                              <span class="title" style={{fontSize: '25px'}}>{ this.props.dispatchDashboard.clearedCallsForToday }</span>
                          </div>                       
                      </div> 
                    </div> :null
             }
             {
               this.props.tailsAccessList[dispatchDashboardTailsTitles.InProgress.toLowerCase()]?                
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"> 
                      <div class="card card-stats">
                        <div class="card-header" data-background-color="orange"><i style={{width:'42px'}} class="fa fa-2x fa-tasks" aria-hidden="true"></i></div>
                        <div class="card-content">
                            <p class="category">Invoices in progress</p>
                            <span class="title" style={{fontSize: '25px'}}>{ this.inProgress() }</span>
                        </div>                       
                      </div> 
                    </div>:null
             }
                    </>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
  return {
      authInfo: state.authInfo,
      reportDashboard:state.reportDashboar,
      dispatchDashboard:state.dispatchDashboard  
    }
};

export default connect(mapStateToProps)(Dispatcher);