import * as actionTypes from '../actions/actionTypes';
import moment from 'moment'
const defaultReportDashboard={
            callComplete: {},
            salvageCount: {},
            dailyDeposit: {},
            storageCount: {},
            unitsReleased: {},
            noAbandonedVehicles: {},
            paylessInventory: {},
            titledcalls:0,
            impoundcalls:[]          
}
export const reportsDashboardReducer = (state = defaultReportDashboard, action) => {
    switch (action.type) {
      case actionTypes.GET_CALLS_COMPLETED:
        return {...state,callComplete:action.callsComplete};
      case actionTypes.GET_SALVAGE_VEHICLE_COUNT:
        return {...state,salvageCount:action.salvageCount};
      case actionTypes.GET_DEPOSITS:
        return {...state,dailyDeposit:action.dailyDeposit};
      case actionTypes.GET_STORAGE_COUNT:
        return {...state,storageCount:action.storageCount};
      case actionTypes.GET_RELEASE_UNITS:
        return {...state,unitsReleased:action.unitsReleased};
      case actionTypes.GET_NO_AVRREPORT:
        return {...state,noAbandonedVehicles:action.noAbandonedVehicles};
      case actionTypes.GET_PAYLESS_INVENTORY:
        return {...state,paylessInventory:action.paylessInventory};
        case actionTypes.GET_IMPOUND_CALLS:
          return {...state,impoundcalls:action.impoundcalls};
          case actionTypes.GET_TITLED_CALLS:
            return {...state,titledcalls:action.titledcalls};
      default:
        return state;
    }
};