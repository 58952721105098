import React, { Component, Fragment } from 'react';
import {Route, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import Header from '../layout/header/header';
import SideMenu from '../layout/sidebar/sidebar';
import DispatcherDashboarPage from '../../container/dashboard/dispatcherDashboardPage';
import ReportChartPage from '../../container/reportChart/reportChartPage';
import AdminAccountDetailPage from '../../container/adminAccountDetail/adminAccountDetailPage';
import Admin from '../Admin/admin';
import Reports from '../Reports/reports';
import Footer from '../Footer/footer';
import {logoutUser} from '../../actions/authenticateActions'
import {Spinner} from 'react-redux-spinner';
class Home extends Component {
    render() {
      return (
        <Fragment><Spinner />
            <div className="pre-loader" style={{display: "none"}}></div>   
          <Scrollbars style={{height:'100vh'}}>
          <Header accessLevels={this.props.accessLevel||{}} dashboardAccess={this.props.dashboardAccess} 
          onLogout={()=>{this.props.dispatch(logoutUser((successMsg)=>{
            this.props.history.push('/logout')
          },(error)=>{

          }))}} />
          <SideMenu accessLevels={this.props.accessLevel||{}} dashboardAccess={this.props.dashboardAccess} />  
          <div class="page-content" style={{paddingTop:'0px'}}>
            <div class="container-fluid">
              <section class="tabs-section">
                <div class="tab-content">  
                <span class="circle-icon" title="Refresh"><i class="fa fa-refresh"></i></span>       
                  <Route exact path="/summarydashboard" component={DispatcherDashboarPage} />
                  <Route eaxct path="/admin-dashboard" component={Admin} />
                  <Route eaxct path="/reports-dashboard" component={Reports} />
                  <Route path="/reportschart" component={ReportChartPage} />
                  <Route path="/adminaccountdetail" component={AdminAccountDetailPage} />
                  <Route exact path="/" component={DispatcherDashboarPage} />
                </div>
              </section>
            </div>
          </div>
          <Footer />
          </Scrollbars>
        </Fragment>
      );
    }
  }
  const mapStateToProps = (state, ownProps) => {
    return {
      authInfo: state.authInfo,
      accessLevel:state.accessLevel,
      dashboardAccess:state.dashboardAccess
    }
  };
  export default withRouter(connect(mapStateToProps)(Home));