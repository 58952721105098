import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeaderMenu from './headerMenu'
import { getUserName } from '../../../actions/authenticateActions';
class Header extends Component {
  constructor(props){
    super(props);
    this.toggleMenu=this.toggleMenu.bind(this);
  }
  toggleMenu=(event)=>{
    event.preventDefault();
    if(document.body.classList.contains('menu-left-opened')){
      event.currentTarget.classList.remove('is-active');
      document.body.classList.remove('menu-left-opened');
      document.documentElement.style.overflow="auto";
    }else{
      event.currentTarget.classList.add('is-active');
      document.body.classList.add('menu-left-opened');
      document.documentElement.style.overflow="hidden";
    }
  }
    render() {
      return (
       
<header className="site-header">
  <div className="container-fluid"> <Link to="/" className="site-logo-text"><img src={require('../../../assets/img/logo-2-white.png')} height="50" width="85" alt="ACT" className="block-center img-rounded" /></Link>
    <button className="hamburger hamburger--htla" onClick={this.toggleMenu}> <span>toggle menu</span> </button>
    <div className="site-header-content">
      <div className="site-header-content-in">
        <div className="site-header-shown">
          <div className="dropdown user-menu">
            <button className="dropdown-toggle" id="dd-user-menu" type="button" title={(getUserName() || '')} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{(getUserName() || '').length>15?getUserName().substring(0, 15):(getUserName() || '')} <img src={require('../../../assets/img/avatar-2-64.png')} alt=""/> </button>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dd-user-menu"> 
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="" onClick={(e)=>{
                this.props.onLogout();
              }}>
              <span className="font-icon glyphicon glyphicon-log-out"></span>Logout</Link> </div>
          </div>
          <button type="button" className="burger-right"> <i className="font-icon-menu-addl"></i> </button>
        </div>
        <div className="mobile-menu-right-overlay"></div>
        <HeaderMenu accessLevels={this.props.accessLevels} dashboardAccess={this.props.dashboardAccess} />     
      </div>
    </div>
  </div>
</header>
        
      );
    }
  }
  
  export default Header;