import storage from 'redux-persist/es/storage';
import { persistCombineReducers } from 'redux-persist';
import { pendingTasksReducer} from 'react-redux-spinner';
import {authenticateReducer, accessLevelReducer,dashboardAccessReducer} from './authenticateReducer';
import {reportsDashboardReducer} from './reportsDashboardReducer';
import {dispatchDashboardReducer} from './dispatchDashboardReducer';
import {adminDashboardReducer} from './adminDashboardReducer';
import {setNotificationRefReducer} from './setNotificationRefReducer';

export default persistCombineReducers({ key: 'root', storage:storage,blacklist:['reportDashboard','dispatchDashboard','refNotify'] },{
  authInfo: authenticateReducer,
  accessLevel:accessLevelReducer,
  pendingTasks: pendingTasksReducer,
  dashboardAccess:dashboardAccessReducer,
  reportDashboard:reportsDashboardReducer,
  dispatchDashboard:dispatchDashboardReducer,
  adminDashboard:adminDashboardReducer,
  refNotify:setNotificationRefReducer
});