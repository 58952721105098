import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {Doughnut} from 'react-chartjs-2';
const options={
    responsive: true,
    maintainAspectRatio: false,
    title: {
        display: true,
        text: 'All Calls'
    }    
}

class CallsCompletedChart extends Component{
    constructor(props){
        super(props);
        this.state={            
            chartObj:{
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [],
                    hoverBackgroundColor: [],
                    borderColor: []
                }]
            },
        }      
    }
    componentWillMount=()=>{

        var graphColors = [];
        var graphOutlines = [];
        var hoverColor = [];
        
       
        var internalDataLength = this.props.reportDashboard.callComplete.callsByReason.length;
        var col = 0;
        while (col <= internalDataLength) {
            var randomR = Math.floor((Math.random() * 130) + 100);
            var randomG = Math.floor((Math.random() * 130) + 100);
            var randomB = Math.floor((Math.random() * 130) + 100);
        
            var graphBackground = "rgb(" 
                    + randomR + ", " 
                    + randomG + ", " 
                    + randomB + ")";
            graphColors.push(graphBackground);
            
            var graphOutline = "rgb(" 
                    + (randomR - 80) + ", " 
                    + (randomG - 80) + ", " 
                    + (randomB - 80) + ")";
            graphOutlines.push(graphOutline);
            
            var hoverColors = "rgb(" 
                    + (randomR + 25) + ", " 
                    + (randomG + 25) + ", " 
                    + (randomB + 25) + ")";
            hoverColor.push(hoverColors);
            
        col++;
        };
        let _chartObj=this.state.chartObj;
        _chartObj.datasets[0].backgroundColor=graphColors;
        _chartObj.datasets[0].hoverBackgroundColor=hoverColor; 
        _chartObj.datasets[0].borderColor=graphOutlines;          
        _chartObj.labels=[];
        _chartObj.datasets[0].data=[];
        if(this.props.reportDashboard.callComplete.callsByReason.length>0){
        this.props.reportDashboard.callComplete.callsByReason.map((value,item)=>{
            _chartObj.labels.push(value.reason);
            _chartObj.datasets[0].data.push(value.count);           
        })
        }
        this.setState({ chartObj:_chartObj}); 
    }
    render(){
        return(
            <div class="row">
                <div class="pull-right col-md-12"> 
                    <Link class="btn btn-info" to="/reports-dashboard"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back </Link>
                </div>
                <div class="clear"></div>
                <div class="col-md-8 col-md-offset-2" style={{backgroundColor: 'rgba(0, 0, 0, 0.14)'}}>           
                    <Doughnut height="440" data={this.state.chartObj} options={options}/> 
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        reportDashboard:state.reportDashboard
        //refNotify:state.refNotify
    }
};

export default connect(mapStateToProps)(CallsCompletedChart);