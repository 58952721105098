import * as actionTypes from './actionTypes';
import {serviceHTTP} from '../util/service';
import{store}  from '../store/configurestore';
import {getAccessToken,getCompanyId,refreshToken} from '../actions/authenticateActions';
import moment from 'moment';

export const getInvoiceMoreThan = (range) => {
    return (dispatch) => {
      //dispatch(spinnerActions.showSpinner());      
      let url="search/account/invoiceMoreThanThirtyDays?query=&companyId="+getCompanyId()+"&noOfDays="+range;
      return serviceHTTP.get(url,undefined,getAccessToken(),'A')
        .then(response => {
          if(response.status==200){
            dispatch(
              {
                  type: actionTypes.ADMIN_GET_INV_MORETHAN,
                  invoiceMoreThan:response
              });
          }else{
            if(response.status===401){
              dispatch(refreshToken());
           }else{
            dispatch(
              {
                  type: actionTypes.ADMIN_GET_INV_MORETHAN,
                  invoiceMoreThan:[]
              });
           }             
          }
          //dispatch(spinnerActions.hideSpinner());          
        })
        .catch(error => {
          //dispatch(spinnerActions.hideSpinner());
          dispatch(
            {
                type: actionTypes.ADMIN_GET_INV_MORETHAN,
                invoiceMoreThan:[]
            });
          throw(error);
        });
    };
};

export const getExpireAccounts = (range) => {
  return (dispatch) => {
    //dispatch(spinnerActions.showSpinner());      
    let url="search/account/expireAccounts?query=&companyId="+getCompanyId()+"&noOfDays="+range;
    return serviceHTTP.get(url,undefined,getAccessToken(),'A')
      .then(response => {
        if(response.status==200){
          dispatch(
            {
                type: actionTypes.ADMIN_GET_EXPIRE_ACCOUNTS,
                expireAccounts:response
            });
        }else{
          if(response.status===401){
            dispatch(refreshToken());
         }else{
          dispatch(
            {
                type: actionTypes.ADMIN_GET_EXPIRE_ACCOUNTS,
                expireAccounts:[]
            });
         }             
        }
        //dispatch(spinnerActions.hideSpinner());          
      })
      .catch(error => {
        //dispatch(spinnerActions.hideSpinner());
        dispatch(
          {
              type: actionTypes.ADMIN_GET_EXPIRE_ACCOUNTS,
              expireAccounts:[]
          });
        throw(error);
      });
  };
};
export const saveInvoiceMoreThanDays = (range) => {
  return (dispatch) => {
        dispatch({
            type: actionTypes.ADMIN_GET_INV_MORETHAN_DAYS,
            invoiceMoreThanDays:range
        })
       }
}
export const saveExpireAccountsDays = (range) => {
  return (dispatch) => {
        dispatch({
            type: actionTypes.ADMIN_GET_EXPIRE_ACCOUNTS_DAYS,
            expiryAccountsDays:range
        })
       }
}
export const getAllAccounts = () =>{
  return (dispatch) => {
    //dispatch(spinnerActions.showSpinner());      
    let url="account/searchNameAndType?searchQuery=&limit=null&accountName=&accountType=&accountStatus=0,1&companyId="+getCompanyId();
    return serviceHTTP.get(url,undefined,getAccessToken(),'A')
      .then(response => {
        if(response.status==200){
          dispatch(
            {
                type: actionTypes.ADMIN_GET_ALL_ACCOUNTS,
                allAccounts:response
            });
        }else{
          if(response.status===401){
            dispatch(refreshToken());
         }else{
          dispatch(
            {
                type: actionTypes.ADMIN_GET_ALL_ACCOUNTS,
                allAccounts:[]
            });
         }             
        }
        //dispatch(spinnerActions.hideSpinner());          
      })
      .catch(error => {
        //dispatch(spinnerActions.hideSpinner());
        dispatch(
          {
              type: actionTypes.ADMIN_GET_ALL_ACCOUNTS,
              allAccounts:[]
          });
        throw(error);
      });
  };
}
