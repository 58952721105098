import * as actionTypes from './actionTypes';
import {serviceHTTP} from '../util/service';
//import {utilMethods} from '../util/util';
import {store} from '../store/configurestore';
import {getCompanyId} from '../actions/authenticateActions';
import moment from 'moment';
import * as spinnerActions from '../actions/spinnerAction';
let inprogressGetCallsCompleted=false;
let inprogreSgetSalvageCount=false;
let inprogressGetDailyDeposit=false;
let inGetStorageCount=false;
let inprogressGetUnitsReleased=false;
let inprogressGetNoAbandonedVehicles=false;
let inprogressGetPaylessInventory=false;
let inprogressGetImpoundcalls=false;
let inprogressGetTitledcalls=false;

const checkIsStatusInprog=()=>{
  return (inprogressGetCallsCompleted || 
  inprogreSgetSalvageCount || inprogressGetDailyDeposit 
  || inGetStorageCount || inprogressGetUnitsReleased 
  || inprogressGetNoAbandonedVehicles || inprogressGetPaylessInventory
  || inprogressGetImpoundcalls || inprogressGetTitledcalls)?false:true
}

export const getCallsCompleted = (fromDate,toDate) => {
    return (dispatch) => {
      dispatch(spinnerActions.showSpinner());
      inprogressGetCallsCompleted=true;
      let url="getCallsCount?fromDate="+moment(fromDate).format('YYYY-M-D')+"&toDate="+moment(toDate).format('YYYY-M-D')+"&companyId="+getCompanyId()
      return serviceHTTP.get(url,undefined,undefined,'R')
        .then(response => {
          if(!response.error){
            dispatch(
              {
                  type: actionTypes.GET_CALLS_COMPLETED,
                  callsComplete:response
              });
          }else{ 
            dispatch(
              {
                  type: actionTypes.GET_CALLS_COMPLETED,
                  callsComplete:{}
              });
          } 
          inprogressGetCallsCompleted=false;
           if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());          
        })
        .catch(error => { 
          inprogressGetCallsCompleted=false;
           if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());
          throw(error);
        });
    };
};
export const getSalvageCount = (fromDate,toDate) => {
  return (dispatch) => {
    dispatch(spinnerActions.showSpinner());
    inprogreSgetSalvageCount=true;
    let url="getSalvageVehiclesCount?fromDate="+moment(fromDate).format('YYYY-M-D')+"&toDate="+moment(toDate).format('YYYY-M-D')+"&companyId="+getCompanyId()
    return serviceHTTP.get(url,undefined,undefined,'R')
      .then(response => {
        if(!response.error){
          dispatch(
            {
                type: actionTypes.GET_SALVAGE_VEHICLE_COUNT,
                salvageCount:response
            });
        }else{ 
          dispatch(
            {
                type: actionTypes.GET_SALVAGE_VEHICLE_COUNT,
                salvageCount:{}
            });
            
        } 
        inprogreSgetSalvageCount=false;
        if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());        
      })
      .catch(error => {
        inprogreSgetSalvageCount=false;
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());
        throw(error);
      });
  };
};
export const getDailyDeposit = (fromDate,toDate) => {
  return (dispatch) => {
    dispatch(spinnerActions.showSpinner());
    inprogressGetDailyDeposit=true;
    let url="getDeposits?fromDate="+moment(fromDate).format('YYYY-M-D')+"&toDate="+moment(toDate).format('YYYY-M-D')+"&companyId="+getCompanyId()
    return serviceHTTP.get(url,undefined,undefined,'R')
      .then(response => {
        if(!response.error){
          dispatch(
            {
                type: actionTypes.GET_DEPOSITS,
                dailyDeposit:response
            });
        }else{ 
          dispatch(
            {
                type: actionTypes.GET_DEPOSITS,
                dailyDeposit:{}
            });
        }  
        inprogressGetDailyDeposit=false;    
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());    
      })
      .catch(error => {
        inprogressGetDailyDeposit=false;
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());
        throw(error);
      });
  };
};
export const getStorageCount = (fromDate,toDate) => {
  return (dispatch) => {
    dispatch(spinnerActions.showSpinner());
    inGetStorageCount=true;
    let url="getStorageCount?fromDate="+moment(fromDate).format('YYYY-M-D')+"&toDate="+moment(toDate).format('YYYY-M-D')+"&companyId="+getCompanyId()
    return serviceHTTP.get(url,undefined,undefined,'R')
      .then(response => {
        if(!response.error){
          dispatch(
            {
                type: actionTypes.GET_STORAGE_COUNT,
                storageCount:response
            });
        }else{ 
          dispatch(
            {
                type: actionTypes.GET_STORAGE_COUNT,
                storageCount:{}
            });
        }    
        inGetStorageCount=false;  
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());    
      })
      .catch(error => {
        inGetStorageCount=false;
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());
        throw(error);
      });
  };
};
export const getUnitsReleased = (fromDate,toDate) => {
  return (dispatch) => {
    dispatch(spinnerActions.showSpinner());
    inprogressGetUnitsReleased=true;
    let url="getReleaseUnits?fromDate="+moment(fromDate).format('YYYY-M-D')+"&toDate="+moment(toDate).format('YYYY-M-D')+"&companyId="+getCompanyId()
    return serviceHTTP.get(url,undefined,undefined,'R')
      .then(response => {
        if(!response.error){
          dispatch(
            {
                type: actionTypes.GET_RELEASE_UNITS,
                unitsReleased:response
            });
        }else{ 
          dispatch(
            {
                type: actionTypes.GET_RELEASE_UNITS,
                unitsReleased:{}
            });
        } 
        inprogressGetUnitsReleased=false;   
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());    
      })
      .catch(error => {
        inprogressGetUnitsReleased=false;
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());
        throw(error);
      });
  };
};
export const getNoAbandonedVehicles = (fromDate,toDate) => {
  return (dispatch) => {
    dispatch(spinnerActions.showSpinner());
    inprogressGetNoAbandonedVehicles=true;
    let url="getNoAVRReport?fromDate="+moment(fromDate).format('YYYY-M-D')+"&toDate="+moment(toDate).format('YYYY-M-D')+"&companyId="+getCompanyId()
    return serviceHTTP.get(url,undefined,undefined,'R')
      .then(response => {
        if(!response.error){
          dispatch(
            {
                type: actionTypes.GET_NO_AVRREPORT,
                noAbandonedVehicles:response
            });
        }else{ 
          dispatch(
            {
                type: actionTypes.GET_NO_AVRREPORT,
                noAbandonedVehicles:{}
            });
        }      
        inprogressGetNoAbandonedVehicles=false;
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());    
      })
      .catch(error => {
        inprogressGetNoAbandonedVehicles=false;
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());
        throw(error);
      });
  };
};
export const getPaylessInventory = (fromDate,toDate) => {
  return (dispatch) => {
    dispatch(spinnerActions.showSpinner());
    inprogressGetPaylessInventory=true;
    let url="getPaylessInventory?fromDate="+moment(fromDate).format('YYYY-M-D')+"&toDate="+moment(toDate).format('YYYY-M-D')+"&companyId="+getCompanyId()
    return serviceHTTP.get(url,undefined,undefined,'R')
      .then(response => {
        if(!response.error){
          dispatch(
            {
                type: actionTypes.GET_PAYLESS_INVENTORY,
                paylessInventory:response
            });
        }else{ 
          dispatch(
            {
                type: actionTypes.GET_PAYLESS_INVENTORY,
                paylessInventory:{}
            });
           
        } 
        inprogressGetPaylessInventory=false;
        if (checkIsStatusInprog())
     dispatch(spinnerActions.hideSpinner());         
      })
      .catch(error => {
        inprogressGetPaylessInventory=false;
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());
        throw(error);
      });
  };
};
export const getImpoundcalls = (fromDate,toDate) => {
  return (dispatch) => {
    dispatch(spinnerActions.showSpinner());
    inprogressGetImpoundcalls=false;
    let url="unitsAdded?fromDate="+moment(fromDate).format('YYYY-M-D')+"&toDate="+moment(toDate).format('YYYY-M-D')+"&companyId="+getCompanyId()
    return serviceHTTP.get(url,undefined,undefined,'R')
      .then(response => {
        if(!response.error){
          dispatch(
            {
                type: actionTypes.GET_IMPOUND_CALLS,
                impoundcalls:response
            });
        }else{ 
          dispatch(
            {
                type: actionTypes.GET_IMPOUND_CALLS,
                impoundcalls:[]
            });
            inprogressGetImpoundcalls=false;
             if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner()); 
        }   
        inprogressGetImpoundcalls=false;  
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());     
      })
      .catch(error => {
        inprogressGetImpoundcalls=false;  
        if (checkIsStatusInprog())
         dispatch(spinnerActions.hideSpinner());   
        throw(error);
      });
  };
};

export const getTitledcalls = (fromDate,toDate) => {
  return (dispatch) => {
    dispatch(spinnerActions.showSpinner());
    inprogressGetTitledcalls=true;
    let url="vehicleTitled?fromDate="+moment(fromDate).format('YYYY-M-D')+"&toDate="+moment(toDate).format('YYYY-M-D')+"&companyId="+getCompanyId()
    return serviceHTTP.getText(url,undefined,undefined,'R')
      .then(response => {
        if(!response.error){
          dispatch(
            {
                type: actionTypes.GET_TITLED_CALLS,
                titledcalls:response
            });
        }else{ 
          dispatch(
            {
                type: actionTypes.GET_TITLED_CALLS,
                titledcalls:0
            });
        } 
        inprogressGetTitledcalls=false;
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());       
      })
      .catch(error => {
        inprogressGetTitledcalls=false;
         if (checkIsStatusInprog())
          dispatch(spinnerActions.hideSpinner());
        throw(error);
      });
  };
};

