import {BASE_URL,REPORT_BASE_URL,DISP_BASE_URL,ADMIN_BASE_URL} from './constants';
class ServiceHTTP{
  authenticate(userName,password,subDomain){
    console.log('base url :',BASE_URL)
    let fullUrl=BASE_URL+'userAuthService/loginUser';
    return fetch(fullUrl, {
      method: 'post',
      headers:{"Content-Type":"application/json"},
      body: JSON.stringify( {
        "userName":userName,
        "password":password,
        "subdomain":subDomain,
        "clientId":"my-trusted-client",
        "secretId":"secret"
      })
    }).then((resp)=>{
      return resp.json();
    });
  }
/*   authenticate(userName,password){
    console.log('base url :',BASE_URL)
    let fullUrl=BASE_URL+'oauth/token?grant_type=password&username=' + userName + '&password=' + password;
    return fetch(fullUrl, {
      method: 'post',
      headers: {
          'Authorization': 'Basic ' + btoa('my-trusted-client:secret'),
          'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((resp)=>{
      return resp.json();
    });
  } */
  getAccessTokenWithRefreshToken=(refreshToken)=>{
    let _serStatusCode=0;
    let fullUrl=BASE_URL+'oauth/token?grant_type=refresh_token&refresh_token='+refreshToken;
    return fetch(fullUrl, {
      method: 'post',
      headers: {
         'Authorization': 'Basic ' + btoa('my-trusted-client:secret'),
      }
    }).then((resp)=>{
      _serStatusCode=resp.status;      
      return resp.json();
    }).then((response)=>{ 
      response.status=_serStatusCode;     
      return response;      
     },(error)=>{
       console.log('refreshtoken-error :',error);
   })
  }

  logOut=(token)=>{
    let _serStatusCode=0;
    let fullUrl=DISP_BASE_URL+'dashboard/userLogout?access_token='+token;
    return fetch(fullUrl, {
      method: 'GET',
      headers: {
        "Accept":"application/json",
        "Content-Type":"application/json"
      }
    }).then((resp)=>{
      _serStatusCode=resp.status;      
      return resp.json();
    }).then((response)=>{ 
      response.status=_serStatusCode;     
      return response;      
     },(error)=>{
       console.error('token authentication-error :',error);
   })
  }

  get(url,headers={"Accept":"application/json","Content-Type":"application/json"},token=undefined,urlType=''){
    let _serStatusCode=0;
    let _BASE_URL=''
    if(urlType=="R")
      _BASE_URL=REPORT_BASE_URL;
    else if(urlType=="D")
      _BASE_URL=DISP_BASE_URL;
    else if(urlType=="A")
      _BASE_URL=ADMIN_BASE_URL;
      else
      _BASE_URL=BASE_URL;
    
    let fullUrl=_BASE_URL+url+ ((token||'') === '' ? '' :  '&access_token='+token )     
    return fetch(fullUrl,
      {
            method:"GET",
            headers:headers
      }
    )
    .then((resp)=>{
      _serStatusCode=resp.status;      
      return resp.json();
    }).then((response)=>{ 
      response.status=_serStatusCode;     
      return response;      
     },(error)=>{
        console.log('del-error :',error);        
      })
  }  

  getText(url,headers={"Accept":"application/json","Content-Type":"application/json"},token=undefined,urlType=''){
    let _serStatusCode=0;
    let _BASE_URL=''
    if(urlType=="R")
      _BASE_URL=REPORT_BASE_URL;
    else if(urlType=="D")
      _BASE_URL=DISP_BASE_URL;
    else if(urlType=="A")
      _BASE_URL=ADMIN_BASE_URL;
      else
      _BASE_URL=BASE_URL;
    
    let fullUrl=_BASE_URL+url+ ((token||'') === '' ? '' :  '&access_token='+token )     
    return fetch(fullUrl,
      {
            method:"GET",
            headers:headers
      }
    )
    .then((resp)=>{
      _serStatusCode=resp.status;      
      return resp.json();
    }).then((response)=>{      
      return response;      
     },(error)=>{
        console.log('del-error :',error);        
      })
  }  
  post(url,data,headers={"Content-Type":"application/json"},token=undefined,urlType=''){
    let _serStatusCode=0;
    let _BASE_URL=''
    if(urlType=="R")
      _BASE_URL=REPORT_BASE_URL;
    else if(urlType=="D")
      _BASE_URL=DISP_BASE_URL;
    else if(urlType=="A")
      _BASE_URL=ADMIN_BASE_URL;
      else
      _BASE_URL=BASE_URL;
    let fullUrl=_BASE_URL+url+ ((token||'')===''?'':'&access_token='+token)
    return fetch(fullUrl,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      }
    )
    .then((resp)=>{
      _serStatusCode=resp.status;      
      return resp.json();
    }).then((response)=>{ 
      response.status=_serStatusCode;     
      return response;      
     },(error)=>{
        console.log('del-error :',error);        
      })
    
  } 
  put(url,data,headers={"Content-Type":"application/json"},token=undefined){
    let _serStatusCode=0;
    let fullUrl=BASE_URL+url+ (token||'')===''?'':'&access_token='+token;
    return fetch(fullUrl,
      {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(data)
      }
    )
    .then((resp)=>{
      _serStatusCode=resp.status;      
      return resp.json();
    }).then((response)=>{ 
      response.status=_serStatusCode;     
      return response;      
     },(error)=>{
        console.log('del-error :',error);        
      })
  }
  delete(url,headers={"Content-Type":"application/json"},token=undefined){
    let _serStatusCode=0;
    let fullUrl=BASE_URL+url+ (token||'')===''?'':'&access_token='+token;
    return fetch(fullUrl,
      {
        method: 'DELETE',
        headers: headers
      }
    )
    .then((resp)=>{
      _serStatusCode=resp.status;      
      return resp.json();
    }).then((response)=>{ 
      response.status=_serStatusCode;     
      return response;      
     },(error)=>{
        console.log('del-error :',error);        
      })
  }
}
export const serviceHTTP=new ServiceHTTP();

/* class EasyHTTP {
  // Make an HTTP GET Request 
  async get(url) {
    const response = await fetch(url);
    const resData = await response.json();
    return resData;
  }

  // Make an HTTP POST Request
  async post(url, data) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    const resData = await response.json();
    return resData;
   
  }

   // Make an HTTP PUT Request
   async put(url, data) {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    
    const resData = await response.json();
    return resData;
  }

  // Make an HTTP DELETE Request
  async delete(url) {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json'
      }
    });

    const resData = await 'Resource Deleted...';
    return resData;
  }

 }

 export const http = new EasyHTTP(); */