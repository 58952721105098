export const BASE_URL = 'https://dispatcherapi.allcityapp.com/actdispatcher';
 //export const BASE_URL = 'http://localhost:8088/actdispatcher';
export const access_token = (function () {
    return localStorage.getItem('access_token');
});
// export const homeUrl = 'http://localhost:8080/acthome'
export const homeUrl = 'https://dev.allcityapp.com/acthome'
// export const logoutUrl = 'https://dev.allcityapp.com/acthome'
// export const logoutUrl = 'https://www.allcityapp.com/acthome'


export const forgetOptions={
    FORGET_USER:'forgetUser',
    FORGET_PASS:'forgetPass',
    FORGET_BOTH:'forgetBoth'
}