import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {notify} from '../../util/notifications';
import { FormValidation } from "calidation";
import Background from '../../assets/img/bg_body.jpg';
import {forgetOption} from '../../util/constants'
import { forgetOptions } from '../../services/constants';
import * as authenticateActions from '../../actions/authenticateActions';
const pageTitle="Reset Password";
class ResetPassword extends Component {
    constructor() {
        super();
        this.state = {
                forgetToken:'',
                newPassword:'',
                conformPwd:'',
                empEmail:'',
                userId:''
        }
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForgetToken=this.validateForgetToken.bind(this);
    }
    componentWillMount=()=>{
        let _queryString=queryString.parse(this.props.location.search)
        this.setState({
            forgetToken:(_queryString.forgetToken||''),
            empEmail:(_queryString.empEmail),
            userId:(_queryString.userid)
        });
    }
    onChangeValues = (event) => {
        let _state = this.state;
        _state[event.currentTarget.name] = event.currentTarget.value;
        this.setState(_state
        );
    }
    handleSubmit = (fields, errors, isValid) => {
        if (fields.isValid) {
             const { dispatch, history } = this.props;
            dispatch(authenticateActions.resetPassword(fields.fields.newPassword, this.state.forgetToken,this.state.empEmail,this.state.userId,(success) => {
                notify.createNotification('success',success,this.props.refNotify);
                history.push('/login');
            }, (error) => {
                notify.createNotification('error',error,this.props.refNotify);
            })); 
        } else {
            //notify.createNotification('error','Something is wrong',this.props.refNotify);
            console.log("Please fill all the required fields", errors);
        }
    }
    validateForgetToken=(forgetToken)=>{
        const { dispatch } = this.props;
        dispatch(authenticateActions.isValidForgetToken(forgetToken,(success) => {
            this.setState({isValidToken:true});            
        }, (error) => {
            this.setState({isValidToken:false});
        }));
    }
    
    render() {
        document.title=pageTitle;
        const config = {
            newPassword: {
                isRequired: "Enter Confirm Password",
                isRegexMatch: {
                    message: 'Password must be at least 8 characters with at least 1 uppercase letter, 1 lowercase letter, and 1 number.',
                    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9~@#$!^*()_+=[\]{}|\\,.?: -]*$/,
                }
            },
            conformPwd:{
                isRequired: "Confirm Password",
                isEqual: ({ fields }) => ({
                    message: 'Password must be same as New Password.',
                    value: fields.newPassword,
                    validateIf: fields.newPassword.length > 0, // this can be a boolean too!
                })
            }
        };
        return (
            <section className="wrapper" style={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: "no-repeat,no-repeat",
                backgroundPosition: "center center,center center",
                backgroundSize: "cover,cover"
            }}>
                <div className="row row-table" style={{ height: '100%', padding: '50px 0px' }}>
                    <div className="col-lg-3 col-md-6 col-sm-8 col-xs-12 align-middle">
                    
                        
                        <FormValidation name="frmLogin" className="mb-lg" config={config}
                                    onSubmit={this.handleSubmit
                                    } >
                                    {({ fields, errors, submitted }) => (
                        <div className="panel panel-default">
                            <p className="text-center mb-lg">
                                <br />
                                <a href="#">
                                    <img src={require('../../assets/img/logo-2-white.png')} style={{ height: '70px' }} alt="ACT" className="block-center img-rounded" />
                                </a>
                            </p>
                            <p className="text-center mb-lg">
                                <strong>Reset Password</strong>
                            </p>
                           
                            <div className="panel-body"> 
                            <div className={(submitted && errors.newPassword) ? "form-group has-feedback has-error has-danger" : "form-group has-feedback"}>
                                    <input id="newPassword" name="newPassword" type="password" placeholder="Enter Password"
                                        className="form-control" value={this.state.newPassword} onChange={(e)=>{this.onChangeValues(e)}} />
                                    <span className="fa fa-envelope form-control-feedback text-muted"></span>
                                                {(submitted && errors.newPassword) ?
                                                    <div className="invalid-feedback with-errors" >
                                                        <span className="text-danger">{errors.newPassword}</span>
                                                    </div>
                                                    : null}
                            </div>                           
                            
                            <div className={(submitted && errors.conformPwd) ? "form-group has-feedback has-error has-danger" : "form-group has-feedback"}>
                                    <input id="conformPwd" name="conformPwd" type="password" placeholder="Confirm Password"
                                        className="form-control" value={this.state.conformPwd} onChange={(e)=>{this.onChangeValues(e)}} />
                                    <span className="fa fa-envelope form-control-feedback text-muted"></span>
                                                {(submitted && errors.conformPwd) ?
                                                    <div className="invalid-feedback with-errors" >
                                                        <span className="text-danger">{errors.conformPwd}</span>
                                                    </div>
                                                    : null}
                            </div>
                            <p className="text-center">
                            * Password must be at least 8 characters with at least 1 uppercase letter, 1 lowercase letter, and 1 number
                            </p>
                                <button type="submit" className="btn btn-block btn-primary">Submit </button>

                            </div>
                           
                        </div>
                        )}</FormValidation>

                    
                    
                    </div> 
                    
                </div>
            </section>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        refNotify:state.refNotify
    }
};

export default withRouter(connect(mapStateToProps)(ResetPassword));