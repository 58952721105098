import * as actionTypes from '../actions/actionTypes'
export const authenticateReducer = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.AUTHENTICATE_USER:
        return action.authInfo;
      default:
        return state;
    }
  };
const defaultAccessLevels={
  accessLevel:{}
}
export const accessLevelReducer = (state = defaultAccessLevels, action) => {
    switch (action.type) {
      case actionTypes.SET_ACCESS_LEVELS:
        return action.accessLevel;        
      default:
        return state;
    }
  };
  const defaultDashboardAccess={
    dashboardAccess:{}
  }
  export const dashboardAccessReducer = (state = defaultDashboardAccess.dashboardAccess, action) => {
    switch (action.type) {
        case actionTypes.SET_DASHBOARD_ACCESS_LEVELS:
        return action.dashboardAccess;
      default:
        return state;
    }
  };