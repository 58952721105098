import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {notify} from '../../util/notifications';
import { FormValidation } from "calidation";
import Background from '../../assets/img/bg_body.jpg';
import {forgetOption} from '../../util/constants'
import { forgetOptions } from '../../services/constants';
import * as authenticateActions from '../../actions/authenticateActions';
const pageTitle="Account Recovery";
class ForgetPassword extends Component {
    constructor() {
        super();
        this.state = {
                emailId: "",
                forgetOption: forgetOptions.FORGET_USER
        }
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    onChangeValues = (event) => {
        let _state = this.state;
        _state[event.currentTarget.name] = event.currentTarget.value;
        this.setState(_state
        );
    }
    handleSubmit = (fields, errors, isValid) => {
        if (fields.isValid) {
             const { dispatch, history } = this.props;
            dispatch(authenticateActions.forgetPassword(fields.fields.emailId, this.state.forgetOption,(success) => {
                notify.createNotification('success',success,this.props.refNotify);
            }, (error) => {
                notify.createNotification('error',error,this.props.refNotify);
            })); 
        } else {
            //notify.createNotification('error','Something is wrong',this.props.refNotify);
            console.log("Please fill all the required fields", errors);
        }
    }
    render() {
        document.title=pageTitle;
        const config = {
            emailId: {
                isRequired: "User email is required!",
                isEmail: 'Please enter a valid e-mail address'
            }
        };
        return (
            <section className="wrapper" style={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: "no-repeat,no-repeat",
                backgroundPosition: "center center,center center",
                backgroundSize: "cover,cover"
            }}>
                <div className="row row-table" style={{ height: '100%', padding: '50px 0px' }}>
                    <div className="col-lg-3 col-md-6 col-sm-8 col-xs-12 align-middle">
                    <FormValidation name="frmLogin" className="mb-lg" config={config}
                                    onSubmit={this.handleSubmit
                                    } >
                                    {({ fields, errors, submitted }) => (
                        <div className="panel panel-default">
                            <p className="text-center mb-lg">
                                <br />
                                <a href="#">
                                    <img src={require('../../assets/img/logo-2-white.png')} style={{ height: '70px' }} alt="ACT" className="block-center img-rounded" />
                                </a>
                            </p>
                            <p className="text-center mb-lg">
                                <strong>Account Recovery</strong>
                            </p>
                           
                            <div className="panel-body">
                            
                            <div className="form-group">
                            <input type="radio" name="forgetOption" value={forgetOptions.FORGET_USER} 
                            checked={forgetOptions.FORGET_USER===this.state.forgetOption} onChange={(e)=>{
                                this.onChangeValues(e);
                            }} /> {'Forgot User Name'}<br />
                            <input type="radio" name="forgetOption"  value={forgetOptions.FORGET_PASS} checked={forgetOptions.FORGET_PASS===this.state.forgetOption} onChange={(e)=>{
                                this.onChangeValues(e);
                            }} /> {'Forgot Password'}<br />
                            <input type="radio" name="forgetOption"  value={forgetOptions.FORGET_BOTH} checked={forgetOptions.FORGET_BOTH===this.state.forgetOption} onChange={(e)=>{
                                this.onChangeValues(e);
                            }} /> {'Forgot Both (User Name and Password)'}
                            </div>
                            <div className={(submitted && errors.emailId) ? "form-group has-feedback has-error has-danger" : "form-group has-feedback"}>
                                    <input id="emailId" name="emailId" type="text" placeholder="Enter User Email Id"
                                        className="form-control" value={this.state.emailId} onChange={(e)=>{this.onChangeValues(e)}} />
                                    <span className="fa fa-envelope form-control-feedback text-muted"></span>
                                                {(submitted && errors.emailId) ?
                                                    <div className="invalid-feedback with-errors" >
                                                        <span className="text-danger">{errors.emailId}</span>
                                                    </div>
                                                    : null}
                            </div>
                                <button type="submit" className="btn btn-block btn-primary">Submit </button>

                            </div>
                           
                        </div>
                        )}</FormValidation>
                    </div> 
                    
                </div>
            </section>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        refNotify:state.refNotify
    }
};

export default withRouter(connect(mapStateToProps)(ForgetPassword));