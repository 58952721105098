import React, { Component,Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {getAccessToken } from '../../../actions/authenticateActions';
import SideMenuCls from '../sidebar/sideMenuCls';
class HeaderMenu extends Component {
    constructor(props){
        super(props);
        this.handleRedirectClick=this.handleRedirectClick.bind(this);
        this.getRedirectUrl=this.getRedirectUrl.bind(this)
      }
      handleRedirectClick=(base,root,token)=>{
        console.log(base,root,token);
        window.location = window.location.origin+base+root //+'?access_token='+token;
      }
      getRedirectUrl=(base,root,token)=>{
        return window.location.origin+base+root //+'?access_token='+token;
      }      
    render(){
      const sideMenuCls=new SideMenuCls();
        return(
        <div className="site-header-collapsed">
                      <ul className="nav navbar-nav">
                      {
                           sideMenuCls.getMenuList().map((mainItem,mkey)=>{
                          if(sideMenuCls.havingAccess(mainItem,this.props.accessLevels,this.props.dashboardAccess)){
                              return(
                                mainItem.type==='I'?
                                <li key={mkey}>
                                <a  href={this.getRedirectUrl('',mainItem.path,getAccessToken())}
                                //href="javascript:void(0)" 
                                  //  onClick={()=>this.handleRedirectClick('',mainItem.path,getAccessToken())}
                                    ><i className="fa fa-tachometer"></i> { mainItem.sidebarName}</a>
                                </li>:
                              <li  key={mkey} className={mainItem.className}>
                              <Link to={mainItem.path} className="dropdown-toggle" data-toggle="dropdown"><i className={mainItem.icon} aria-hidden="true"></i> {mainItem.sidebarName}<b className="caret"></b></Link>            
                              {mainItem.sub.length > 0 ? (<ul className="dropdown-menu multi-level">
                              {
                                  mainItem.sub.map((subItem,k) =>{
                                  if(sideMenuCls.havingAccess(subItem,this.props.accessLevels,this.props.dashboardAccess)){
                                  return(
                                      <li  key={k} className={subItem.submenu.length > 0 ? 'dropdown-submenu' : ''}>{subItem.submenu.length > 0 ? <a className="dropdown-toggle">{subItem.sidebarName}</a> : <>{window.location.pathname.split('/').includes(mainItem.rootName) && ((subItem.isLaunch||false)===false)?
                                      <Link to={subItem.path}>{subItem.sidebarName}</Link>:
                                      (((subItem.isLaunch||false)===true)?
                                      <a href={this.getRedirectUrl('',subItem.path,getAccessToken())}
                                      //href="javascript:void(0)" 
                                     // onClick={()=>this.handleRedirectClick('',subItem.path,getAccessToken())}
                                      >
                                      {subItem.sidebarName}</a>:
                                      <a href={this.getRedirectUrl(subItem.baseURL,subItem.path,getAccessToken())}
                                     // href="javascript:void(0)" 
                                      //onClick={()=>this.handleRedirectClick(mainItem.baseURL,subItem.path,getAccessToken())}
                                      >
                                      {subItem.sidebarName}</a>)}</>}
                                      {subItem.submenu.length > 0 ?
                                      <ul className="dropdown-menu">
                                      {
                                      subItem.submenu.map((subMenuItem,key)=>{
                                          if(sideMenuCls.havingAccess(subMenuItem,this.props.accessLevels,this.props.dashboardAccess)){
                                          return(
                                          <li key={key}>
                                            {window.location.pathname.split('/').includes(mainItem.rootName)&& ((subItem.isLaunch||false)===false)?
                                                <Link to={subMenuItem.path}>{subMenuItem.sidebarName}</Link>
                                                :
                                                (((subItem.isLaunch||false)===true)?
                                            <a href={this.getRedirectUrl('',subItem.path,getAccessToken())}
                                            //href="javascript:void(0)" 
                                           // onClick={()=>this.handleRedirectClick('',subItem.path,getAccessToken())}
                                            >{subItem.sidebarName}</a>:
                                            <a href={window.location.origin+subMenuItem.baseURL+subMenuItem.path}>{subMenuItem.sidebarName}</a>)
                                                }
                                          </li>
                                          )
                                          }
                                      })
                                      }</ul> : null}
                                      </li>                    
                                  ) 
                                  }                 
                                  })
                              }</ul>):null}
                              </li>
                          )
                          }          
                          }) 
                      }             
                              </ul> 
                         
                      </div>
        )
    }
}
export default HeaderMenu