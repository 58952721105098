import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Range from '../../dropdownrange/range';

class AccountsWithoutNewInvoices extends Component {
    constructor(props){
        super(props);
        this.state={        
        }
    }
    
    render() {
        const {daysCount}=this.props;
        return (
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"> 
                    <div class="card card-stats">
                        <div class="card-header" data-background-color="purple"><i style={{width:'52px'}} class="fa fa-3x fa-search" aria-hidden="true"></i></div>
                        <div class="card-content">
                            <Range applyCallback={(event,range)=>{
                                    event.preventDefault();                                
                                    this.props.getInvoiceMoreThan(range);
                                    this.props.saveInvoiceMoreThanDaysCount(range);
                                }}/>
                            <p class="category">Account without new invoices for {daysCount} days or more</p>
                            <span class="title" style={{fontSize: '25px'}}>{this.props.adminDashboardInvoiceMoreThan.length}</span>
                        </div>
                        <div class="card-footer text-right">
                            <div class="stats">
                                <Link class="btn btn-sm btn-primary" to="/adminaccountdetail/accountswithoutnewinvoicesdetails" style={{marginBottom: '0px', marginRight: '0px', padding: '2px 6px'}}>
                                <i class="fa fa-table"></i>
                                </Link>
                            </div>
                        </div>                        
                    </div> 
                </div>                                                        
        );
    }
}
export default AccountsWithoutNewInvoices;