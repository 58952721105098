
import moment from 'moment';
class UtilMethods{
    setCookie=(cName, cValue, minutes)=> {
        var d = new Date();
        d.setTime(d.getTime() + (minutes*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires;
    }
    enumerateDaysBetweenDates = (startDate, endDate)=> {
        let now = endDate;let dates = [];        
       while (now.isSameOrAfter(startDate)) {
              dates.push(now.format('YYYY/MM/DD'));
              now.subtract(1, 'days');
          }
        return dates;
    };
    getYearByMonthsFromNow=(limit=5)=>{
        let yearMonthsList=[]; 
        let groupYears=[];
        let now=moment().startOf('month');
        let endDate=moment().startOf('year');
        endDate.subtract(limit,"year");        
         while (endDate.isSameOrBefore(now)) {
            groupYears.push({year:now.year(),month:now.format('MMM'),monthNo:now.format('M')});
            now.subtract(1, 'Month');
        }
        yearMonthsList = groupYears.reduce(function (r, a) {
            r[a.year] = r[a.year] || [];
            r[a.year].push(a);
            return r;
        }, Object.create(null)); 
        return yearMonthsList;
    }
    groupBy=(list, props)=> {
        return (list||[]).reduce((a, b) => {
           (a[b[props]] = a[b[props]] || []).push(b);
           return a;
        }, {});
      }
      isNumber=(input)=>{
        if(String(input).match(/^-{0,1}\d+$/)){
            return true
          }else if(String(input).match(/^\d+\.\d+$/)){
            return true
          }else{
            return false
          }
    }
}
export const utilMethods=new UtilMethods() 