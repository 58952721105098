
class Notify {
    createNotification = (type,message,NotificationManager) => { 
      NotificationManager.clearNotifications();       
          switch (type) {
            case 'info':
              NotificationManager.addNotification({
                title:'Info',
                message: message,
                level: 'info',
                position:'tc'
              });
            break;
            case 'success':
              NotificationManager.addNotification({
                title:'Success',
                message: message,
                level: 'success',
                position:'tc'
              });
            break;
            case 'warning':
              NotificationManager.addNotification({
                title:'Warning',
                message: message,
                level: 'warning',
                position:'tc'
              });
            break;
            case 'error':
              NotificationManager.addNotification({
                title:'Error',
                message: message,
                level: 'error',
                position:'tc'
              });
            break;
            default:
                NotificationManager.addNotification({
                  title:'Info',
                  message: message,
                  level: 'info',
                  position:'tc'
                });
            break;
          }
  }
}
export const notify=new Notify();