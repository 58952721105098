import * as actionTypes from './actionTypes';
import {serviceHTTP} from '../util/service';
//import {utilMethods} from '../util/util';
import {getAccessToken,getCompanyId,refreshToken} from '../actions/authenticateActions';
import moment from 'moment';

export const getCallList = () => {
    return (dispatch) => {
     // dispatch(spinnerActions.showSpinner());      
      let url="dashboard/select/callList?companyId="+getCompanyId();
      return serviceHTTP.get(url,undefined,getAccessToken(),'D')
        .then(response => {
          if(response.status==200){
            dispatch(
              {
                  type: actionTypes.DISP_GET_CALL_LIST,
                  callList:response
              });
          }else{
            if(response.status===401){
              dispatch(refreshToken());
           }else{
            dispatch(
              {
                  type: actionTypes.DISP_GET_CALL_LIST,
                  callList:{callList:[]}
              });
           }             
          }
       //   dispatch(spinnerActions.hideSpinner());          
        })
        .catch(error => {
         // dispatch(spinnerActions.hideSpinner());
          dispatch(
            {
                type: actionTypes.DISP_GET_CALL_LIST,
                callList:{callList:[]}
            });
          throw(error);
        });
    };
};
export const getTruckDetails = (fromDate,toDate) => {
  return (dispatch) => {
    //dispatch(spinnerActions.showSpinner());
    let url="display/truckDetails?companyId="+getCompanyId();
    return serviceHTTP.get(url,undefined,getAccessToken(),'D')
      .then(response => {
        if(response.status==200){
          dispatch(
            {
                type: actionTypes.DISP_GET_TRUCK_DETAILS,
                truckDetails:response
            });
        }else{ 
          if(response.status===401){
            dispatch(refreshToken());
         }else{
          dispatch(
            {
                type: actionTypes.DISP_GET_TRUCK_DETAILS,
                truckDetails:[]
            });
          }
        }          
      })
      .catch(error => {
        //dispatch(spinnerActions.hideSpinner());
        throw(error);
      });
  };
};

export const getTotalClearedCallsForToday = (fromDate,toDate) => {
    return (dispatch) => {
      //dispatch(spinnerActions.showSpinner());
      let url="dashboard/get/totalClearedCallsForToday?companyId="+getCompanyId();
      return serviceHTTP.get(url,undefined,getAccessToken(),'D')
        .then(response => {
          if(response.status==200){
            dispatch(
              {
                  type: actionTypes.DISP_GET_TOTAL_CREATED_CALLS_FOR_TODAY,
                  clearedCallsForToday:response
              });
          }else{ 
            if(response.status===401){
              dispatch(refreshToken());
           }else{
            dispatch(
              {
                  type: actionTypes.DISP_GET_TOTAL_CREATED_CALLS_FOR_TODAY,
                  clearedCallsForToday:0
              });
          }  }        
        })
        .catch(error => {
          //dispatch(spinnerActions.hideSpinner());
          throw(error);
        });
    };
  };
