import * as actionTypes from './actionTypes';
import {serviceHTTP} from '../util/service';
import {utilMethods} from '../util/util';
import {store} from '../store/configurestore';

export const authenticateUser = (userName,password,subDomain,success,fail) => {
    return (dispatch) => {
      //dispatch(spinnerActions.showSpinner());
      return serviceHTTP.authenticate(userName,password,subDomain)
        .then(response => {
          if(response.statusCode===200){
           // utilMethods.setCookie('access_token',response.access_token,response.expires_in);
            dispatch(
              {
                  type: actionTypes.AUTHENTICATE_USER,
                  authInfo:{oaAuthTokens:response.loginUserResp.oaAuthTokens,userDetails:response.loginUserResp.userDetails,isAuthenticated:true}
              });
             if(response.loginUserResp.userDetails.isReset===1){
                dispatch(getAccessLevels(response.loginUserResp.userDetails.employeeId,
                  response.loginUserResp.userDetails.companyId,
                  response.loginUserResp.oaAuthTokens.access_token));                  
             }
             success(response);              
              //dispatch(spinnerActions.hideSpinner());                          
          }else{
            response.isAuthenticated=false; 
            dispatch(
              {
                  type: actionTypes.AUTHENTICATE_USER,
                  authInfo:response
              });
             //dispatch(spinnerActions.hideSpinner());
              fail();
          }          
        })
        .catch(error => {
          //dispatch(spinnerActions.hideSpinner());          
          dispatch(
            {
                type: actionTypes.AUTHENTICATE_USER,
                authInfo:{}
            });
            throw(error);
        });
    };
};

export const getAccessLevels=(empId,companyId,access_token)=>{
  return (dispatch) => {
    let _url='employee/select/employeeAccessLevel?empId='+empId+'&companyId='+companyId+'&access_token='+access_token;
    //dispatch(spinnerActions.showSpinner());
    return serviceHTTP.get(_url,undefined,'','A')
      .then(response => {
        if(response.status===200){
            //dispatch(spinnerActions.hideSpinner());
            dispatch(
              {
                  type: actionTypes.SET_ACCESS_LEVELS,
                  accessLevel:response.employeeAccess
              });
              dispatch(
                {
                    type: actionTypes.SET_DASHBOARD_ACCESS_LEVELS,
                    dashboardAccess:response.dashboardAccess
                });
        }else{
           //dispatch(spinnerActions.hideSpinner());
           dispatch(
            {
                type: actionTypes.SET_ACCESS_LEVELS,
                accessLevel:undefined
            });
            dispatch(
              {
                  type: actionTypes.SET_DASHBOARD_ACCESS_LEVELS,
                  dashboardAccess:undefined
              });
        }          
      })
      .catch(error => {
        //dispatch(spinnerActions.hideSpinner());
        dispatch(
          {
              type: actionTypes.SET_ACCESS_LEVELS,
              accessLevel:undefined
          });
          dispatch(
            {
                type: actionTypes.SET_DASHBOARD_ACCESS_LEVELS,
                dashboardAccess:undefined
            });
        throw(error);
      });
    
  }
}
export const forgetPassword = (email,forgetOption,success,fail) => {
  return (dispatch) => {
    let _url='userAuthService/recoverUserPassword'
    //dispatch(spinnerActions.showSpinner());
    return serviceHTTP.post(_url,{
      "emailId": email,
      "forgetOption": forgetOption
    },undefined,'')
      .then(response => {
        if(response.statusCode===200){
            //dispatch(spinnerActions.hideSpinner());
            success(response.message);            
        }else{
           //dispatch(spinnerActions.hideSpinner());
            fail(response.statusMsg);
        }          
      })
      .catch(error => {
        //dispatch(spinnerActions.hideSpinner());
        fail(error.message);
        throw(error);
      });
  };
};

export const resetPassword = (newPassword,forgotToken,empEmail,userId,success,fail) => {
  return (dispatch) => {
    let _url='userAuthService/resetPassword'
    //dispatch(spinnerActions.showSpinner());
    return serviceHTTP.post(_url,{
      "confirmPassword": newPassword,
      "newPassword": newPassword,
      "pwdForgottoken": forgotToken,
      "empEmail":empEmail,
      "userId":userId
    },undefined,'')
      .then(response => {
        if(response.statusCode===200){
            //dispatch(spinnerActions.hideSpinner());
            success(response.message);            
        }else{
           //dispatch(spinnerActions.hideSpinner());
            fail(response.statusMsg);
        }          
      })
      .catch(error => {
        //dispatch(spinnerActions.hideSpinner());
        fail(error.message);
        throw(error);
      });
  };
};

export const isValidForgetToken = (forgotToken,success,fail) => {
  return (dispatch) => {
    let _url='userAuthService/isActiveForgetPwdToken/'+forgotToken
    //dispatch(spinnerActions.showSpinner());
    return serviceHTTP.get(_url,undefined,'','')
      .then(response => {
        if(response.statusCode===200){
            //dispatch(spinnerActions.hideSpinner());
            success(response.message);            
        }else{
           //dispatch(spinnerActions.hideSpinner());
            fail(response.message);
        }          
      })
      .catch(error => {
        //dispatch(spinnerActions.hideSpinner());
        fail(error.message);
        throw(error);
      });
  };
};


export const isAuthenticated=()=>{
  if( Object.keys((store.getState()).authInfo).length>0){
    return (store.getState()).authInfo.isAuthenticated
  }else{
    return false;
  }  
}
export const getAccessToken=()=>{
  if( Object.keys((store.getState()).authInfo).length>0){    
    return (store.getState()).authInfo.oaAuthTokens.access_token;
  }else{
    return undefined;
  }
}
export const getCompanyId=()=>{
  if( Object.keys((store.getState()).authInfo).length>0){    
    return (store.getState()).authInfo.userDetails.companyId;
  }else{
    return 0;
  }
}
export const getUserName=()=>{
  if( Object.keys((store.getState()).authInfo).length>0){    
    return (store.getState()).authInfo.userDetails.firstName+' '+(store.getState()).authInfo.userDetails.lastName;
  }else{
    return undefined;
  }
}
export const logoutUser=(success,fail)=>{
  return (dispatch) => {
    return  serviceHTTP.logOut(getAccessToken())
        .then(response => {
          dispatch(
            {
                type: actionTypes.AUTHENTICATE_USER,
                authInfo:{}
            });
            dispatch(
              {
                  type: actionTypes.SET_ACCESS_LEVELS,
                  accessLevel:undefined
              });
              dispatch(
                {
                    type: actionTypes.SET_DASHBOARD_ACCESS_LEVELS,
                    dashboardAccess:undefined
                });
            success('logout successfully')
                 //window.location.href=LAUNCH_LOGIN_URL;
  
        })
        .catch(error => {
          dispatch(
            {
                type: actionTypes.AUTHENTICATE_USER,
                authInfo:{}
            });
            dispatch(
              {
                  type: actionTypes.SET_ACCESS_LEVELS,
                  accessLevel:undefined
              });
              dispatch(
                {
                    type: actionTypes.SET_DASHBOARD_ACCESS_LEVELS,
                    dashboardAccess:undefined
                });
            success('logout successfully')
         // window.location.href = LAUNCH_LOGIN_URL;
            throw error;
        });
  };
  }
export const refreshToken=()=>{
  return (dispatch)=>{
     if(Object.keys((store.getState()).authInfo).length>0){
      return serviceHTTP.getAccessTokenWithRefreshToken((store.getState()).authInfo.oaAuthTokens.refresh_token)
      .then(response => {
        if([401,400].includes(response.status)){
          dispatch(clearSessionData());
          window.location.href='/login';
        }else{
          let _respObj=(store.getState()).authInfo;
          _respObj.access_token=response.access_token;
          _respObj.refresh_token=response.refresh_token;
          _respObj.token_creationTime=response.expires_in;
          dispatch(
            {
                type: actionTypes.AUTHENTICATE_USER,
                authInfo:_respObj
            });
        }
      })
      .catch(error => {
          dispatch(clearSessionData());
          window.location.href='/login';
          throw(error);
        }); 

    }else{
      dispatch(clearSessionData());
      window.location.href='/login';
    }
  } 
}
export const clearSessionData=()=>{
  return (dispatch)=>{
     dispatch(
      {
          type: actionTypes.AUTHENTICATE_USER,
          authInfo:{}
      })
  }  
}
