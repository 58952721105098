import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {Doughnut} from 'react-chartjs-2';
const options={
    responsive: true,
    maintainAspectRatio: false,
    title: {
        display: true,
        text: 'Total Deposits'
    },
    tooltips: {
        callbacks: {
            label: function(tooltipItem, data){
                var html = "";
                for(var dataset in data.datasets){
                    debugger
                    html += ` ${data.labels[tooltipItem.index]} : $ ${(data.datasets[dataset].data[tooltipItem.index]).toFixed(2)}`;
                }
                return html;
            }
        },
    }   
}
class DailyDepositChart extends Component{
    constructor(props){
        super(props);
        this.state={            
            chartObj:{
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#CD853F',
                        '#AF6382',
                        '#BC8F8F',
                        '#FFCE55',
                        '#A3A381',
                        '#3672BC'
                    ],
                    hoverBackgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#CD853F',
                        '#AF6382',
                        '#BC8F8F',
                        '#FFCE55',
                        '#A3A381',
                        '#3672BC'
                    ]
                }]
            },
        }      
    }
    componentWillMount=()=>{
        let _chartObj=this.state.chartObj;            
        _chartObj.labels=[];
        _chartObj.datasets[0].data=[];
        if(this.props.reportDashboard.dailyDeposit.length>0){
        this.props.reportDashboard.dailyDeposit.map((value,item)=>{
            _chartObj.labels.push(value.paymentLocation);
            _chartObj.datasets[0].data.push(value.paymentAmount);           
        })
        }
        this.setState({ chartObj:_chartObj}); 
    }
    render(){
        return(
            <div class="row"> 
                <div class="pull-right col-md-12"> 
                    <Link class="btn btn-info" to="/reports-dashboard"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back </Link>
                </div>
                <div class="clear"></div>
                <div class="col-md-8 col-md-offset-2" style={{backgroundColor: 'rgba(0, 0, 0, 0.14)'}}>           
                    <Doughnut height="440" data={this.state.chartObj} options={options}/> 
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        reportDashboard:state.reportDashboard
        //refNotify:state.refNotify
    }
};

export default connect(mapStateToProps)(DailyDepositChart);