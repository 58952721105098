import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {Doughnut} from 'react-chartjs-2';
let _ = require('underscore');
let graphColors;
let graphOutlines;
let hoverColor;
let filterData=[];
class NoAbandonedVehiclesChart extends Component{
    constructor(props){
        super(props);
        this.state={
            visibleDrillDown:false,
            chartObj1:{
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [],
                    hoverBackgroundColor: [],
                    borderColor: []
                }]
            },
            chartObj2:{
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [],
                    hoverBackgroundColor: [],
                    borderColor: []
                }]
            }, 
            optText: ''
        } 
        this.backToFirstLevel = this.backToFirstLevel.bind(this);     
    }
    componentWillMount(){
        this.noAbandonedVehicleDetails();    
    }
    backToFirstLevel(event){      
        event.preventDefault();
        this.setState({visibleDrillDown:false},
            this.noAbandonedVehicleDetails()
        );      
    }
    noAbandonedVehicleDetails=()=>{
        graphColors = [];
        graphOutlines = [];
        hoverColor = [];
        
       
        let internalDataLength = this.props.reportDashboard.noAbandonedVehicles.noAbandonedVehicleDetails.length;
        let col = 0;
        while (col <= internalDataLength) {
            let randomR = Math.floor((Math.random() * 130) + 100);
            let randomG = Math.floor((Math.random() * 130) + 100);
            let randomB = Math.floor((Math.random() * 130) + 100);
        
            let graphBackground = "rgb(" 
                    + randomR + ", " 
                    + randomG + ", " 
                    + randomB + ")";
            graphColors.push(graphBackground);
            
            let graphOutline = "rgb(" 
                    + (randomR - 80) + ", " 
                    + (randomG - 80) + ", " 
                    + (randomB - 80) + ")";
            graphOutlines.push(graphOutline);
            
            let hoverColors = "rgb(" 
                    + (randomR + 25) + ", " 
                    + (randomG + 25) + ", " 
                    + (randomB + 25) + ")";
            hoverColor.push(hoverColors);
            
        col++;
        };
        let _chartObj1=this.state.chartObj1;            
        _chartObj1.labels=[];
        _chartObj1.datasets[0].data=[];
        _chartObj1.datasets[0].backgroundColor=graphColors;
        _chartObj1.datasets[0].hoverBackgroundColor=hoverColor; 
        _chartObj1.datasets[0].borderColor=graphOutlines;
        
        filterData=_.groupBy(this.props.reportDashboard.noAbandonedVehicles.noAbandonedVehicleDetails,'lotLocation');
        for(let i=0;i<Object.keys(filterData).length;i++){
            let items=filterData[Object.keys(filterData)[i]]; 
            _chartObj1.labels.push(items[0]['lotLocation']);
            _chartObj1.datasets[0].data.push(items.length);                   
        }
    }
    
    handleElementClick(evt){
        if(evt.length>0){
        this.setState({visibleDrillDown:true, optText: evt[0]._chart.active[0]._view.label});
        //console.log('selected Index:',evt[0]._chart.active[0]._index);
        //evt[0]._chart.active[0]._view.label
        let _chartObj2=this.state.chartObj2;            
        _chartObj2.labels=[];
        _chartObj2.datasets[0].data=[];
        let items=filterData[Object.keys(filterData)[evt[0]._chart.active[0]._index]];
        //Linked Chart
        let dataByYearWise=_.groupBy(items,'year');
        graphColors = [];
        graphOutlines = [];
        hoverColor = [];
        
       
        let internalDataLength = Object.keys(dataByYearWise).length;
        let col = 0;
        while (col <= internalDataLength) {
            let randomR = Math.floor((Math.random() * 130) + 100);
            let randomG = Math.floor((Math.random() * 130) + 100);
            let randomB = Math.floor((Math.random() * 130) + 100);
        
            let graphBackground = "rgb(" 
                    + randomR + ", " 
                    + randomG + ", " 
                    + randomB + ")";
            graphColors.push(graphBackground);
            
            let graphOutline = "rgb(" 
                    + (randomR - 80) + ", " 
                    + (randomG - 80) + ", " 
                    + (randomB - 80) + ")";
            graphOutlines.push(graphOutline);
            
            let hoverColors = "rgb(" 
                    + (randomR + 25) + ", " 
                    + (randomG + 25) + ", " 
                    + (randomB + 25) + ")";
            hoverColor.push(hoverColors);
            
        col++;
        }; 
        _chartObj2.datasets[0].backgroundColor=graphColors;
        _chartObj2.datasets[0].hoverBackgroundColor=hoverColor; 
        _chartObj2.datasets[0].borderColor=graphOutlines;   
        for(let j=0;j<Object.keys(dataByYearWise).length;j++){
            let lst=dataByYearWise[Object.keys(dataByYearWise)[j]];              
            _chartObj2.labels.push(lst[0]['year']+'');
            _chartObj2.datasets[0].data.push(lst.length); 

        }
    }  
    }
    render(){
        const options1={
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: true,
                text: 'No AVR'
            }    
        }
        const options2={
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: true,
                text: 'No AVR Vehicles By Year '+this.state.optText
            }    
        }
        return(
            <div class="row"> 
            {this.state.visibleDrillDown === false ? <div class="pull-right col-md-12"> 
                    <Link class="btn btn-info" to="/reports-dashboard"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back </Link>
                </div>:<div class="col-md-8 col-md-offset-2"><button class="pull-right btn btn-info" onClick={this.backToFirstLevel}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button></div>}
                <div class="clear"></div>
            {this.state.visibleDrillDown === false ?
                <div class="col-md-8 col-md-offset-2">
                    <div style={{backgroundColor: 'rgba(0, 0, 0, 0.14)'}}>           
                        <Doughnut height="440" data={this.state.chartObj1} onElementsClick={(evt)=>this.handleElementClick(evt)} options={options1}/> 
                    </div>
                </div>:
                <div class="col-md-8 col-md-offset-2">           
                    <div style={{backgroundColor: 'rgba(0, 0, 0, 0.14)'}}>                               
                        <Doughnut height="440" data={this.state.chartObj2} options={options2}/>                       
                    </div> 
                </div>
            }
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        authInfo: state.authInfo,
        reportDashboard:state.reportDashboard
        //refNotify:state.refNotify
    }
};

export default connect(mapStateToProps)(NoAbandonedVehiclesChart);