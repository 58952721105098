import * as actionTypes from '../actions/actionTypes';
import moment from 'moment'
const defaultDispatchDashboard={
    callList: {callList:[]},
    truckDetails: [],
    clearedCallsForToday:0        
}
export const dispatchDashboardReducer = (state = defaultDispatchDashboard, action) => {
    switch (action.type) {
        case actionTypes.DISP_GET_CALL_LIST:
            return {...state,callList:action.callList};
        case actionTypes.DISP_GET_TRUCK_DETAILS:
            return {...state,truckDetails:action.truckDetails};
        case actionTypes.DISP_GET_TOTAL_CREATED_CALLS_FOR_TODAY:
            return {...state,clearedCallsForToday:action.clearedCallsForToday.totalCalls};
        default:
            return state;
    }
};