import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AccountsWithoutNewInvoicesDetails from '../../components/Admin/accounts/accountswithoutnewinvoices/accountswithoutnewinvoicesdetails';
import AccountsWillExpireDetails from '../../components/Admin/accounts/accountswillexpire/accountswillexpiredetails';
import DisabledAccountsDetails from '../../components/Admin/accounts/disabledaccounts/disabledaccountsdetails';
import CashOnlyDetails from '../../components/Admin/accounts/cashonly/cashonlydetails';
import ExpiredAccountDetails from '../../components/Admin/accounts/expiredaccounts/expiredaccountsdetails';
import AccountByDepartmentDetails from '../../components/Admin/accounts/accountbydepartment/accountbydepartmentdetails';
import {withRouter} from 'react-router-dom';

class AdminAccountDetailPage extends Component {
    render() {
        return ( <div>                                   
                        <Route exact path="/adminaccountdetail/accountswithoutnewinvoicesdetails" component={AccountsWithoutNewInvoicesDetails} />
                        <Route exact path="/adminaccountdetail/accountswillexpiredetails" component={AccountsWillExpireDetails} />
                        <Route exact path="/adminaccountdetail/disabledaccountsdetails" component={DisabledAccountsDetails} />
                        <Route exact path="/adminaccountdetail/cashonlyaccountsdetails" component={CashOnlyDetails} />
                        <Route exact path="/adminaccountdetail/expiredaccountsdetails" component={ExpiredAccountDetails} />
                        <Route exact path="/adminaccountdetail/accountbydepartmentdetails" component={AccountByDepartmentDetails} />
                                           
        </div>)
    }

}
export default withRouter(AdminAccountDetailPage)