import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authenticateActions from './actions/authenticateActions';
import * as notificationActions from './actions/notificationActions';
import NotificationSystem from 'react-notification-system';
import Login from './container/authcomponents/login';
import ForgetPassword from './container/authcomponents/forgetpassword';
import ResetPassword from './container/authcomponents/resetPassword'
import { PrivateRoute } from './components/privateroute';
import Home from './components/Home/home';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import './assets/css/main.css'



class App extends Component {
  constructor(props) {
    super(props);
   
  }
  componentDidMount=()=> {
    this._notificationSystem = this.refs.notificationSystem;
    this.props.setNotifyRef(this.refs.notificationSystem);
  }
  render() {
    return (
      <>
      <NotificationSystem ref="notificationSystem" />
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/forgetpassword" component={ForgetPassword} /> */}
          <Route exact path="/accountrecovery" component={ForgetPassword} />
          <Route exact path="/resetPassword" component={ResetPassword} />
          <PrivateRoute path="/"   component={Home} />         
        </Switch>
      </BrowserRouter>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    authInfo: state.authInfo
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    authenticateUser: (_userName,_passsword) =>{
      dispatch(authenticateActions.authenticateUser(_userName,_passsword))
    },
    setNotifyRef: (_notifyRef) =>{
      dispatch(notificationActions.setNotificationRef(_notifyRef))
    }
  }
};
export default connect(mapStateToProps,mapDispatchToProps)(App);
